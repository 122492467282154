import { useStaticQuery, graphql } from "gatsby"

// const ALL_EPHARMACY_PRODUCTS = graphql`
//   query MedicineSearchProducts {
//     allProducts {
//       nodes {
//         brand
//         molecule
//         productTitle
//         rxRequired
//         rxLevel
//         category
//         classification
//         price_list {
//           price_list
//           vatex_unit_price
//           vat
//           context
//         }
//       }
//     }
//   }
// `

const ALL_EPHARMACY_PRODUCTS = graphql`
  query {
    allAirtableallMedGrocerProducts(
      filter: { data: { ShowOnBeta: { eq: true } } }
    ) {
      nodes {
        data {
          brand: Brand
          drugName: DrugName
          molecule: Molecule
          productTitle: ProductTitle
          rxRequired: RxRequired
          priceList: PriceList
          vatexUnitPrice: VatexUnitPrice
          classification: Classification
          vat: Vat
          maxQuantity: MaxQuantity
          classification: Classification
          description: Description
          drugRxLevel: DrugRxLevel
          skuTags: SKUTags
          category: Category
        }
      }
    }
  }
`

export const useEpharmacyProducts = () => {
  const data = useStaticQuery(ALL_EPHARMACY_PRODUCTS)

  return data
}
